import Nosotros from './../img/nosotros.png'
import Gallery from './GalleryModules/Gallery'
import { slides } from './GalleryModules/imagesSliceGallery1';


export default function QuienesSomos() {

    return (

        <div>
            <div className="row mt-2">

                



            <div className="col-12 col-sm-8  text-center">

                
                

                    <p>
                        <strong style={{ fontSize: '30px' }}>About me</strong><br /><hr></hr>
                        Mi pasión es capturar la esencia de cada proyecto a través de imágenes impactantes y auténticas. Mi objetivo es claro: transmitir de manera fiel la visión de cada proyecto para captar la atención de futuros clientes y potenciar la propagación de tu marca.
                    </p>

                    <p>

                    Trabajo estrechamente contigo para asegurar que el resultado final no solo cumpla, sino que supere tus expectativas. Mi enfoque colaborativo garantiza que cada detalle sea cuidadosamente considerado, y que el proceso sea tan satisfactorio como el resultado.    
                    </p>
                   
                    <p>
                    Déjame ayudarte a dar vida a tu visión y a destacar en el mercado con imágenes que realmente resuenen con tu audiencia.
                    </p>
                   
                </div>

                <div className="col-12 col-sm-4">
                    <img src={Nosotros} width='100%' ></img>
                </div>

            </div>
        </div>
    )

}