

import Gallery from '../modules/GalleryModules/Gallery';
import NivelesTrabajoEnAlturas from '../modules/NivelesTrabajoEnAlturas';
import { slides } from '../modules/GalleryModules/imagesSliceGallery1';


export default function TrabajoEnAlturas() {

    return (
        <div>
            <Gallery slides={slides} ></Gallery>
        </div>
    )

}