import Profesiones from './../img/profesiones.jpg'



export default function SiderA() {
  return (
    <div className="me-12 d- mt-4 text-center"  >
      <div className='row'>
        <div className='col-12'><hr></hr></div>
        <h3>Fotografía & Video</h3>
        <div className='col-12'><hr></hr></div>
      </div>

      <div className='row'>

         <div className="col-12 col-sm-12 boxcenter text-center">
          <p style={{ fontSize: '18px', textAlign: 'justify' }}>
          Mi objetivo es transmitir de manera fiel la esencia o visión de cualquier proyecto a través de imágenes, para captar la atención de futuros clientes, o de incentivar la propagación de la marca. Siempre trabajando hombro a hombro con quién adquiera mis servicios, para que los resultados sean satisfactorios para ambos lados.
         </p>
        </div>

       

      </div>






    </div>

  )
}