import image_01 from '../../../images/gallery_1/01.jpg'
import image_02 from '../../../images/gallery_1/02.jpg'
import image_03 from '../../../images/gallery_1/03.jpg'
import image_04 from '../../../images/gallery_1/04.jpg'
import image_05 from '../../../images/gallery_1/05.jpg'
import image_06 from '../../../images/gallery_1/06.jpg'
import image_07 from '../../../images/gallery_1/07.jpg'
import image_08 from '../../../images/gallery_1/08.jpg'



export const slides = [
    {
      src: image_01,
      title: 'Slide Title One',
      description: 'Slide Description One\n\nthis is new line',
    },
    {
      src: image_02,
      title: 'Slide Title Two',
      description: 'Slide Description Two',
    },
    {
      src: image_03,
      title: 'Slide Title',
      description: 'Slide Description',
    },
    {
      src: image_04,
      title: 'Slide Title',
      description: 'Slide Description',
    },
    {
      src: image_05,
      title: 'Slide Title',
      description: 'Slide Description',
    },  
    {
      src: image_06,
      title: 'Slide Title',
      description: 'Slide Description',
    },  
    {
      src: image_07,
      title: 'Slide Title',
      description: 'Slide Description',
    },  
    {
      src: image_08,
      title: 'Slide Title',
      description: 'Slide Description',
    },    
  ]